const SERVER_URL = process.env.REACT_APP_PROD_BACKEND
const PORT = process.env.REACT_APP_PROD_PORT

export const newConversationCall = async (user, authInfo) => {
  const toSend = {
    user: user,
    accessToken: authInfo.tokens.access.token
  };

  return fetch(SERVER_URL + ":" + PORT + "/v1/conversation/", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toSend), // body data type must match "Content-Type" header
    }
  )
  .then (res => res.text());
}

export const getConversations = async (user, authInfo) => {
  const toSend = {
    user: user,
    accessToken: authInfo.tokens.access.token
  };

  return fetch(SERVER_URL + ":" + PORT + "/v1/conversation/all" + "?" + new URLSearchParams(toSend), 
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
  )
  .then (res => res.json());
}

export const sendContent = async (content, fullChat, chatId, authInfo, interaction=null) => {
  var i = 0
  const context = []
  const history = []
  if (fullChat && fullChat['chat'])
    fullChat['chat'].forEach(item => item['type'] === 'image' ? context.push(item['imageText']) : history.push({'source': item['user'], 'content': item['content']}))

  var toUpload = {'input': content}
  toUpload['context'] = context
  toUpload['history'] = history
  toUpload['id'] = chatId
  if (interaction !== null)
    toUpload['interaction'] = interaction
  toUpload['userId'] = authInfo.user.id
  toUpload['accessToken'] = authInfo.tokens.access.token
  
  return fetch(SERVER_URL + ":" + PORT + "/v1/conversation/predict", 
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(toUpload), // body data type must match "Content-Type" header
    }
  )
      .then(async res => {
        if (res.status == 200 || res.status == 204) {
          var toReturn = await res.text()
          try {
            var jsonReturn = JSON.parse(toReturn);
            if (typeof jsonReturn === 'string') {
              return [{"user": "collabria", "content": jsonReturn, "type": "text"}]
            }
            else
              return jsonReturn
          }
          catch {
            return [{"user": "collabria", "content": toReturn, "type": "text"}]
          }
        }
        alert("Oops! Something went wrong, please try again.");
        throw new Error(res.statusText);
      }

      )
};

export const sendImage = async (content, chatId, authInfo) => {
  const formData = new FormData();


  var filetype = "image/" + content.substring("data:image/".length, content.indexOf(";base64"))


  var file_object = await fetch(content)
  .then(res => res.blob())
  .then(blob => new File([blob], "file",{ type: filetype })
  )

  formData.set('file', file_object);
  formData.set('id', chatId);
  formData.set('userId', authInfo.user.id);
  formData.set('accessToken', authInfo.tokens.access.token);

  const toSend = {
    method: 'POST',
    body: formData
  };

  return fetch(SERVER_URL + ":" + PORT + "/v1/conversation/image", toSend)
  .then (res => res.json())
}

export const sendImageAndContent = async (image, content, fullChat, chatId, authInfo, interaction=null) => {
  const context = []
  const history = []
  if (fullChat && fullChat['chat'])
    fullChat['chat'].forEach(item => item['type'] === 'image' ? context.push(item['imageText']) : history.push({'source': item['user'], 'content': item['content']}))

  const formData = new FormData();

  var filetype = "image/" + image.substring("data:image/".length, content.indexOf(";base64"))


  var file_object = await fetch(image)
  .then(res => res.blob())
  .then(blob => new File([blob], "file",{ type: filetype })
  )

  formData.set('file', file_object);
  formData.set('userId', authInfo.user.id);
  formData.set('accessToken', authInfo.tokens.access.token);
  formData.set('input', content)
  formData.set('context', JSON.stringify(context))
  formData.set('history', JSON.stringify(history))
  formData.set('id', chatId)
  if (interaction !== null)
    formData.set('interaction', interaction)


  const toSend = {
    method: 'POST',
    body: formData
  };
  
  return fetch(SERVER_URL + ":" + PORT + "/v1/conversation/image_predict", 
    toSend
  )
      .then(async res => {
        if (res.status == 200 || res.status == 204) {
          var toReturn = await res.json()
          return toReturn
        }
        alert("Oops! Something went wrong, please try again.");
        throw new Error(res.statusText);
      }

      )
}

const sendContentTest = async (content) => {
  return new Promise((res, rej) => {
    setTimeout(() => res(getRandomResponse()), 1000)
  });
};

const responses = [
  "Certainly, I'm here to help you with any questions or concerns you may have.",
  "I'm at your service, ready and eager to assist you with any inquiries you may have.",
  "Please don't hesitate to ask your question, and I'll provide you with the best answer I can.",
  "Feel free to go ahead and ask your question, and I'll do my utmost to assist you effectively.",
  "You're in good hands! I'm here to assist you with any inquiries or issues you need help with.",
  "I'm ready and willing to assist with any query you may have, so please go ahead and ask away.",
  "Your questions are important to me. Please go ahead and ask, and I'll provide a helpful response.",
  "I'm all ears! What's on your mind? Please feel free to ask any questions or seek assistance. I'm ready and willing to assist with any query you may have, so please go ahead and ask away.",
  "Let's engage in a productive conversation. What can I assist you with today? Ask your question.",
  "I'm here to assist you promptly. Don't hesitate to ask your question, and I'll provide a detailed response.",
  "Your inquiry matters. Feel free to ask, and I'll do my best to provide a comprehensive and informative answer. I'm ready and willing to assist with any query you may have, so please go ahead and ask away.",
  "What can I help you with today? Your questions are welcomed, and I'm prepared to provide insightful responses.",
  "I'm here to make your experience better. Tell me more about your question, and I'll assist you accordingly.",
  "Your satisfaction is my goal. Here to assist you with any query you have—no question is too small or too big. I'm ready and willing to assist with any query you may have, so please go ahead and ask away.",
  "Don't hold back. Feel free to ask anything on your mind, and I'll ensure you receive a thorough and helpful response."
];

const getRandomResponse = () => {
  const randomIndex = Math.floor(Math.random() * responses.length);
  return responses[randomIndex];
}  