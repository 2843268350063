import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import collabriaIcon from '../../assets/Collabria Icon Modified.png';
import TextField from '@mui/material/TextField';

import HelpIcon from '@mui/icons-material/Help';
import DescriptionIcon from '@mui/icons-material/Description';
import RuleIcon from '@mui/icons-material/Rule';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import UploadFile from '@mui/icons-material/UploadFile';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ArrowBack from '@mui/icons-material/ArrowBack';
import CheckCircle from '@mui/icons-material/CheckCircle';


import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';


import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';

import { handleFile } from "../../utils/fileUpload";
import { ImageThumbnail } from '../ImageThumbnail/ImageThumbnail';

const prompts = {
  "question": "Type your question below, and add any context you want with pictures",
  "assignment": "Upload or photograph the part of your assignment you want help with, or type it out",
  "review": "Add an existing test question, or type a concept or question in the box to create new study questions",
  "feedback": "Upload your question and type out your thoughts or work so far"
}


export const InteractionArea = ({addQuestion, isMobile}) => {
  const [interaction, setInteraction] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);

  const theme = useTheme();
  const textBreak = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = e => {
      setText(e.target.value);
  }

  const handleSubmit = () => {
    if (text.trim() !== "" && image !== null)
      addQuestion([image,text], ['image', 'text'], () => null, interaction)
    else if (text.trim() !== "")
      addQuestion(text, 'text', () => null, interaction)
    else if (image !== null)
      addQuestion(image, 'image', () => null, interaction)
  }

  const callHandleFile = (e) => {
    const file = e.target.files[0];
    handleFile(file, setImage);
  };

  return (
    
        <Container
              sx={{
                height: 'auto',
                display: 'flex',
                flexDirection: !isMobile || interaction === "" ? 'row' : "column",
                padding: !isMobile || interaction === "" ? '10vh 0 10vh 0': '0',
                margin: 0,
                alignItems:'flex-start'
              }}
            >
              {interaction === "" ?
              ([<Container sx={{display:'flex', flexDirection:'column'}}>
                <Card sx={{marginBottom:'2.5vh'}} onClick={() => setInteraction("question")}>
                  <CardActionArea sx={{display:'flex', flexDirection:'column', alignItems:"center"}}>
                    <Typography variant='subtitle1' color='grey'>Ask a question</Typography>
                    <HelpIcon sx={{fontSize:120}}/>
                  </CardActionArea>
                </Card>

                <Card sx={{marginBottom:'2.5vh'}} onClick={() => setInteraction("review")}>
                  <CardActionArea sx={{display:'flex', flexDirection:'column', alignItems:"center"}}>
                    <Typography variant='subtitle1' color='grey'>Review for a test</Typography>
                    <RuleIcon sx={{fontSize:120}}/>
                  </CardActionArea>
                </Card>
              </Container>,

              <Container sx={{display:'flex', flexDirection:'column'}}>

                <Card sx={{marginBottom:'2.5vh'}} onClick={() => setInteraction("assignment")}>
                  <CardActionArea sx={{display:'flex', flexDirection:'column', alignItems:"center"}}>
                    <Typography variant='subtitle1' color='grey'>{!textBreak ? "Get help with an assignment" : "Get help"}</Typography>
                    <DescriptionIcon sx={{fontSize:120}}/>
                  </CardActionArea>
                </Card>

                <Card sx={{marginBottom:'2.5vh'}} onClick={() => setInteraction("feedback")}>
                  <CardActionArea sx={{display:'flex', flexDirection:'column', alignItems:"center"}}>
                    <Typography variant='subtitle1' color='grey'>{!textBreak ? "Get feedback on your work" : "Get feedback"}</Typography>
                    <RecordVoiceOverIcon sx={{fontSize:120}}/>
                  </CardActionArea>
                </Card>

              </Container>])
              :
              ([<IconButton onClick={() => setInteraction("")}> <ArrowBack sx={{fontSize:isMobile ? 72 : 80}}/></IconButton>,
               (
                <Container sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                  <Typography sx={{marginBottom:'2.5vh'}} variant="subtitle1">{prompts[interaction]}</Typography>
                  { image == null ? 
                    <Container sx={{display:'flex', justifyContent: 'center', flexDirection:'row'}}>
                    <Card sx={{marginBottom:'2.5vh',  marginRight:"50px",}}>
                      <CardActionArea aria-label="Take picture" component="label" sx={{display:'flex', width: !textBreak ? "200px" : "150px", flexDirection:'column', alignItems:"center"}}>
                      
                        <input hidden type="file" accept="image/*" capture="environment" onChange={callHandleFile}/>
                      <Typography variant='subtitle1' color='grey'>Take a picture</Typography>
                      <PhotoCamera sx={{fontSize:120}}/>
                      </CardActionArea>
                    </Card>
                    <Card sx={{marginBottom:'2.5vh'}}>
                      <CardActionArea component="label" aria-label="Upload image" sx={{display:'flex', width: !textBreak ? "200px" : "150px", flexDirection:'column', alignItems:"center"}}>
                      
                      <input hidden accept="image/*" type="file" onChange={callHandleFile}/>

                      <Typography variant='subtitle1' color='grey'>{!textBreak ? "Upload an existing file" : "Upload file"}</Typography>
                      <UploadFile sx={{fontSize:120}}/>
                      

                    </CardActionArea>
                    </Card>
                  </Container>
                  :
                    <ImageThumbnail src={image} closeImage={() => setImage(null)}/>
                  }
                <Typography sx={{marginBottom:'2.5vh'}} variant="subtitle1">or</Typography>
                <TextField onChange={handleChange} value={text} sx={{width:textBreak ? "100%" : "80%"}}/>

                <IconButton color="success" disabled={image === null && text.trim() === ""} onClick={() => handleSubmit()}><CheckCircle sx={{fontSize:80}}/></IconButton>
                </Container>
              )])
              }
        </Container>
  );
}