import { PrevChatCard } from '../PrevChatCard/PrevChatCard';
import { SettingsArea } from '../SettingsArea/SettingsArea';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

export const PrevChatDrawer = ({ allChats, selectChat, open, toggleDrawer, isMobile, settingsView, toggleSettingsView, isStudent, toggleShowClassModal }) => {

  const width = isMobile ? '50vw' : '25vw';

  const styles = {
    width: width,
    height: '88%',
    overflowY: 'scroll',
    overflowX: 'clip',
    p: 0,
  }

  const handleSettingsClick = () => {
    toggleSettingsView(!settingsView);
  };

  return (
    <Drawer
      // Can add anchor={'position'} to change where the drawer is located
      open={!isMobile || open}
      variant={isMobile ? 'temporary' : 'permanent'}
      onClose={toggleDrawer}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: width,
          height: '12%'
        }}
      >
     
        <ListSubheader sx={{ textAlign: 'center', width: '80%'}}>
          <Typography variant='h5'>
            Previous Chats
          </Typography>
        </ListSubheader>
      </Box>
      <Divider />
      <List
            sx={styles}
           >
            { 
              allChats.length > 0 &&
                allChats.sort((a, b) => b.createdAt - a.createdAt).map((chat) => {
                  return (
                    <PrevChatCard
                      topic={chat.topic}
                      key={chat.id}
                      id={chat.id}
                      selectChat={selectChat}
                      toggleDrawer={toggleDrawer}
                    />
                  );
                })
            }
        </List>
    </Drawer>
  );
}
