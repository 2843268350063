import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import HeaderLogo from '../../assets/Collabria Full logo mock Yellow.png';

export const LoginPage = ({ isMobile, signIn, createAccount, forgotPassword, setAuthInfo }) => {
    const [isCreateAccount, setCreateAccount] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    
    const width = '100%';
    const marginBottom = isMobile ? '12vh' : '';
    const height = isMobile ? '70vh' : '75vh';

    const headerImageStyle = isMobile ? {height: '17.06vw', width: '50.45vw'} : {height: '21.6vh', width: '63.9vh'}

    const buttonContainerWidth = isMobile ? '100%' : '50%';

    //TODO: implement
    const emailRequestValidation = () => {
      const trimmedEmail = email.trim();
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
      const toReturn = (trimmedEmail !== '' && re.test(trimmedEmail));

      if (!toReturn)
        setEmailError('Please enter a valid email address')
      else
        setEmailError('')
      return toReturn
    }

    const passwordValidation = () => {
      if (password.length < 8) {
        setPasswordError('Your password must have at least 8 letters, numbers or symbols');
        return false
      }

      if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
        setPasswordError('Your password must have at least one number and one letter');
        return false
      }

      setPasswordError('')

      return true
    }
    
    //TODO: implement
    const authRequestValidation = () => {
        if (!emailRequestValidation())
          return false;
        if (!passwordValidation())
          return false;
        return true;
    }

    const switchCreateAccount = () => {
        setCreateAccount(ca => !ca);
    };

    const userAuthentication = () => {
        if (authRequestValidation()) {
            if (isCreateAccount)
                createAccount(fullName, email, password).then(response => {
                  if (response.tokens && response.user) {
                    setAuthInfo({
                      'tokens': response['tokens'],
                      'user': response['user']
                    })
                  }
                }).catch(error => {
                  setPasswordError(error.message);
                  setEmailError(' ');
                });
            
            else
                signIn(email, password).then(response => {
                  if (response && response.tokens && response.user) {
                    setAuthInfo({
                      'tokens': response['tokens'],
                      'user': response['user']
                    })
                  }
                }).catch(error => {
                  setPasswordError(error.message);
                  setEmailError(' ');
                });
        }
    };

    const sendForgotPassword = () => {
        if (emailRequestValidation())
            forgotPassword(email);
    }
  
    return (
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '12vh',
          marginBottom: marginBottom,
          padding: 0,
          alignSelf: 'center',
          height: height,
          width: width,
          overflow: 'auto',
          backgroundColor: 'var(--secondary-color-white)',
        }}
      >
        <img 
        alt='Collabria Logo' 
        style={{...headerImageStyle, alignSelf: 'center'}}
        src={HeaderLogo}
      />

        {
            isCreateAccount ?
            [
            
            <Typography
                  variant='subtitle1'
                  color='black'
                  align='center'
                >
                  Full Name
            </Typography>,
            <TextField
            value={fullName}
            onChange={(event) => {
              setFullName(event.target.value);
            }}
            />
            ]
            : null
        }

        <Typography
                  variant='subtitle1'
                  color='black'
                  align='center'
                >
                  Email
        </Typography>

        <TextField
            value={email}
            type='email'
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            error={!(emailError === '')}
            helperText={emailError}
        />

        <Typography
                  variant='subtitle1'
                  color='black'
                  align='center'
                >
                  Password
        </Typography>
        
        <TextField
            value={password}
            type='password'
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            error={!(passwordError === '')}
            helperText={passwordError}
        />

        <Container style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            justifyContent: 'space-between',
            width: buttonContainerWidth,
            margin: 0
        }}>
            <Button variant="contained" onClick={userAuthentication}>{!isCreateAccount ? "Sign in" : "Confirm New Account"}</Button>
            <Button variant="text" onClick={switchCreateAccount}>{!isCreateAccount ? "Create Account" : "Back to sign in"}</Button>
        </Container>


      </Container>
    );
  }  