import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export const ImageThumbnail = ({ src, closeImage }) => {

  return (
    <Card sx={{ maxHeight: '30vh' }}>
      <IconButton
        onClick={closeImage}
        tabIndex={0}
      >
        <ClearIcon />
      </IconButton>
      <CardMedia
        component="img"
        image={src}
        alt="User Uploaded Image"
        sx={{ maxHeight: '30vh' }}
      />
    </Card>
  );
}
