import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

export const VerificationPage = ({ isMobile, verificationFunction }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const width = '100%';
    const marginBottom = isMobile ? '12vh' : '';
    const height = isMobile ? '70vh' : '75vh';

    const token = searchParams.get("token");


    useEffect(() => {
      if (token !== null) 
        verificationFunction(token).then(response => {
          if (response && response.status === 204) {
            navigate("/", { replace: true });
            navigate(0);
          }
        })
    }, [token]);

    
    
  
    return (
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '12vh',
          marginBottom: marginBottom,
          padding: 0,
          alignSelf: 'center',
          alignItems: 'center',
          height: height,
          width: width,
          overflow: 'auto',
          backgroundColor: 'var(--secondary-color-white)',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }  