var getFrontendConversation = (backendConversation) => {
    var toReturn = {}
    toReturn['id'] = backendConversation['id']

    toReturn['createdAt'] = Date.parse(backendConversation['createdAt'])
    //Convert 'messages' on backend to 'chat'
    toReturn['chat'] = backendConversation['messages']

    //Convert 'description' on backend to 'topic
    //Or create a new topic if needed
    if (backendConversation['description'] !== undefined) {
        toReturn['topic'] = backendConversation['description']
    }
    else
        toReturn['topic'] = backendConversation['messages'][0]['itemType'] !== 'image' ? backendConversation['messages'][0]['content'] : backendConversation['messages'][0]['imageText']
        
    //Goes through and renames different chat properties
    for (var j = 0; j<backendConversation['messages'].length; j++) {
        toReturn['chat'][j]['type'] = backendConversation['messages'][j]['itemType']
        toReturn['chat'][j]['user'] = backendConversation['messages'][j]['role']
    }
    return toReturn

}

export { getFrontendConversation };