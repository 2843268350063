import HeaderLogo from '../../assets/Collabria Full logo mock Yellow.png';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';


export const Header = ({ createNewChat, toggleDrawer, isMobile }) => {

  const width = isMobile ? '100%' : '75vw';

  return (
    <AppBar
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        height: '12vh',
        width: width,
        padding: 0,
        zIndex: 3,
        backgroundColor: 'var(--secondary-color-white)',
      }}
    >
      {
        isMobile &&
          <Button 
            onClick={toggleDrawer}
            sx={{ position: 'fixed', left: 10 }}
          >
            <MenuIcon 
              fontSize='large'
              color='action'
            />
          </Button>
      }
      <img 
        alt='Collabria Logo' 
        src={HeaderLogo}
        style={{ height: '90%' }}
      />
      <Button
        onClick={() => createNewChat()}
        sx={{ position: 'fixed', right: 10 }}
      >
        <AddIcon
          fontSize='large'
          color='action'
        />
      </Button>
    </AppBar>
  );
}
