import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import collabIcon from '../../assets/Collabria Icon Modified APP YellowB.png';
import React, { useEffect } from 'react';
import { Box } from '@mui/material';

const avatarStyle = {
  width: 'calc(40 + 1vw)',
  height: 'calc(40 + 1vh)'
};

export const ChatBox = ({ user, content, type, displayLoadingStatus }) => {
  const isUser = user === 'user';
  const isImage = type === 'image';

  const boxColor = isUser ? 'var(--primary-color-white)' : 'var(--secondary-color-grey)';

  const icon = (
    isUser
      ? <Avatar style={avatarStyle}>
          <PersonIcon />
        </Avatar>
      : <Avatar 
          variant="rounded"
          alt={isUser ? 'User Icon' : 'Collabria Icon'} 
          src={collabIcon}
          sx={avatarStyle}
        />
  );

  const info = (
    isImage
      ? <Box sx={{maxWidth: '40%', display: 'grid', alignItems: 'center'}} >
          {
            displayLoadingStatus &&
            <Box
              sx={{
                gridColumn: 1,
                gridRow: 1,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress
                size={'50%'}
              />
            </Box>
          }
          <img
            src={content}
            alt='User Content'
            style={{
              maxWidth: '100%',
              filter: displayLoadingStatus && 'opacity(50%)',
              gridColumn: 1,
              gridRow: 1,
            }}
          />
        </Box>
        : <ListItemText
            primary={content}
            primaryTypographyProps={{ style: { whiteSpace: "pre-wrap" } }}
            sx={{ textWrap: 'wrap', wordBreak: 'break-word' }}
          />
  );

  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset", 
      window.MathJax.Hub,
      node.current
   ]);
  }
  
  return (
    <ListItem 
      style={{ 
        backgroundColor: boxColor, 
        margin: '1vh 0 1vh 0',
      }}
      ref={node}
    >
      <ListItemAvatar>
        {icon}
      </ListItemAvatar>
      {info}
    </ListItem>
  );
}
