export const getChatsFromStorage = () => {
  const chats = localStorage.getItem('chats');
  return chats ? JSON.parse(chats) : [];
}

export const setChatsInStorage = chats => {
  const stringifiedChats = JSON.stringify(chats);
  localStorage.setItem('chats', stringifiedChats);
}

export const getAuthInfoFromStorage = () => {
  const auth = localStorage.getItem('auth');
  return auth ? JSON.parse(auth) : {};
}

export const setAuthInfoInStorage = auth => {
  const stringifiedAuth = JSON.stringify(auth);
  localStorage.setItem('auth', stringifiedAuth);
}