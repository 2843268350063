import React, { useRef, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import collabriaIcon from '../../assets/Collabria Icon Modified.png';
import { ChatBox } from '../ChatBox/ChatBox';
import { InteractionArea } from '../InteractionArea/InteractionArea';

export const ChatArea = ({ currentChat, isMobile, uploading, addQuestion }) => {
  const scrollRef = useRef();

  const lastMessageIndex = currentChat.chat?.length - 1;

  const width = isMobile ? '100%' : '74vw';
  const marginBottom = isMobile ? '12vh' : '';
  const height = isMobile ? '70vh' : '75vh';

  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [currentChat]);

  return (
    <Container
      ref={scrollRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '12vh',
        marginBottom: marginBottom,
        marginRight: 0,
        padding: 0,
        right: 0,
        height: height,
        width: width,
        overflow: 'auto',
        backgroundColor: 'var(--secondary-color-white)',
      }}
    >
      {
        currentChat.chat?.length
          ? currentChat.chat.map((query, index) => {

              let displayLoadingStatus = uploading && currentChat.chat[lastMessageIndex]['user'] !== 'collabria' && currentChat.chat[lastMessageIndex] && lastMessageIndex === index;

              return(
                <ChatBox 
                  user={query.user}
                  content={query.content}
                  type={query.type}
                  key={index}
                  displayLoadingStatus={displayLoadingStatus}
                />
              );
            })
          : <InteractionArea addQuestion={addQuestion} isMobile={isMobile}/>
      }
    </Container>
  );
}