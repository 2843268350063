import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import ChatIcon from '@mui/icons-material/Chat';
import { FileUpload } from '../FileUpload/FileUpload';
import { ImageThumbnail } from '../ImageThumbnail/ImageThumbnail';
import Tracking from '../../utils/tracking';

export const UserInputField = ({ addQuestion, isMobile, uploading, setUploading, setShowAlert }) => {
  const [value, setValue] = useState('');
  const [image, setImage] = useState(null);

  const regex = /^(?!\s+$)./;
  const isPointer = !uploading && ((value && regex.test(value)) || image) ? 'pointer' : 'default';
  const width = isMobile ? '100%' : '74.5vw';

  const setUploaded = isImg => {
    setUploading(false);
    isImg && setShowAlert(true);
  };

  const setDuringUpload = _ => setUploading(true);

  const handleChange = e => {
      setValue(e.target.value);
  }

  const closeImage = () => {
    setImage(null);
  }

  const handleImageUpload = (base64Image) => {
    setImage(base64Image)
    Tracking.logAction("Loaded image");
    setValue('');
  }

  const handleSubmit = () => {
    if (!uploading) {
      setDuringUpload();
      if (image && value && regex.test(value)) {
        addQuestion([image, value], ['image', 'text'], setUploaded);
        setImage(null);
        setValue('');
      }
      else if (image) {
        addQuestion(image, 'image', setUploaded);
        setImage(null);
      } else if (value && regex.test(value)) {
        addQuestion(value, 'text', setUploaded);
        setValue('');
      }
    }
  }
//caretColor: image ? 'transparent' : ''
  return (
    <TextField
      id="outlined-multiline-flexible"
      label="How can we help you?"
      multiline
      fullWidth
      sx={{
        position: 'fixed',
        bottom: 1,
        right: 3,
        zIndex: 2,
        backgroundColor: 'var(--secondary-color-white)',
        width: width,
        caretColor: '',
      }}
      value={value && value}
      onChange={handleChange}
      onFocus={() => Tracking.logAction('Focused chat input')}
      InputProps={{
        startAdornment: 
          image 
            ? <ImageThumbnail 
                src={image}
                closeImage={closeImage}
              /> 
            : <FileUpload 
                handleImageUpload={handleImageUpload}
                tabIndex={0}
              />,
        endAdornment: 
          <ChatIcon
            color={(!value && !image) || uploading ? 'disabled' : ''}
            sx={{ cursor: isPointer }}
            onClick={() => handleSubmit()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit();
            }}
            tabIndex={0}
          />
      }}
    />
  )
}
