import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Tracking from '../../utils/tracking';
import {handleFile} from '../../utils/fileUpload';

export const FileUpload = ({ handleImageUpload }) => {

  const callHandleFile = (e) => {
    const file = e.target.files[0];
    handleFile(file, handleImageUpload);
  };

  return (
    <IconButton
      aria-label="Upload picture"
      component="label"
      onClick={() => Tracking.logAction("Clicked image upload")}
    >
      <input
        hidden
        accept="image/*"
        type="file"
        onChange={callHandleFile}
      />
      <PhotoCamera />
    </IconButton>
  );
}