const getbase64 = (file) => {
    return new Promise((resolve,reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result)
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

const handleFile = (file, handleImageUpload) => {
    getbase64(file).then(base64 => {
      handleImageUpload(base64.toString());
      console.debug("file stored",base64);
    });
};

export {getbase64, handleFile}