import { hotjar } from 'react-hotjar';
import { Mixpanel } from './mixpanel';

let actions = {
    init: () => {
        hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
        Mixpanel.initialize();
    },
    logAction: (actionName, props={}) => {
        hotjar.event(actionName);
        Mixpanel.track(actionName, props);
    },
    identifyUser: (userId, toAlias=null) => {
        hotjar.identify(userId);
        Mixpanel.identify(userId);
        if (toAlias !== null)
            Mixpanel.alias(userId);
    },
    logNewPage: (pagePath) => {
        hotjar.stateChange(pagePath);
        Mixpanel.track("New page", {"path": pagePath});
    }
}

export default actions;