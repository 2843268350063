import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { LoginPage } from './components/LoginPage/LoginPage';
import { ChatPage } from './components/ChatPage/ChatPage';
import { getChatsFromStorage, setChatsInStorage, getAuthInfoFromStorage, setAuthInfoInStorage } from './utils/localStorageMethods';
import { sendContent, sendImage, sendImageAndContent, newConversationCall, getConversations } from './utils/conversationCalls';
import { signIn, createAccount, forgotPassword, userVerification, refreshAuthentication } from './utils/authCalls';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { VerificationPage } from './components/VerificationPage/VerificationPage';
import Tracking from './utils/tracking';
import { getFrontendConversation } from './utils/convertConversation';
Tracking.init();

const App = () => {
  const [allChats, setAllChats] = useState([]);
  const [currentChat, setCurrentChat] = useState({});
  const [drawerOpen , setDrawerOpen] = useState(false);
  const [authInfo, setAuthInfo] = useState({});
  const [refreshTimerID, setRefreshTimerID] = useState(null);
  const [settingsView, toggleSettingsView] = useState(false);
  const [isStudent, setIsStudent] = useState(true);
  const [showClassModal, toggleShowClassModal] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //Runs once on load of app: 
  //Gets auth info from storage and sets auth info accordingly
  useEffect(() => {
    setAuthInfo(() => getAuthInfoFromStorage());
  }, [])


  //When auth info is updated, if auth info has been set we set all chats
  //We also log the user in to tracking
  useEffect(() => {
    if (authInfo.user && authInfo.tokens) {
      Tracking.identifyUser(authInfo.user.id);
      setAllChats(() => getChatsFromStorage());
    }
  }, [authInfo]);


  //If currentChat has no keys, we make a brand new chat (depending on if the server returns a new chat id)
  useEffect(() => {
    if (authInfo.user && authInfo.tokens && Object.keys(currentChat).length === 0) {
      getChats();
      createNewChat();
    }
  })

  //If currentChat exists, is changed, and has items in it, we save the new chat to all chats
  //If the id happens to be the same, we nuke the other chat with the same id
  useEffect(() => {
    if (currentChat.chat && currentChat.chat.length > 0) {
      setAllChats((prev) => {
        const allOtherChats = prev.filter(chat => chat.id !== currentChat.id);
        const newChats = [...allOtherChats, currentChat];
        setChatsInStorage(newChats);
        return newChats.sort((a, b) => b.createdAt - a.createdAt);
      });
    }
  }, [currentChat])

  //If authInfo has been updated, we store it in memory
  //Then we create a new refresh token request and time it for slightly before the time the access token is set to expire
  useEffect(() => {
    if (Object.keys(authInfo).length !== 0) {
      setAuthInfoInStorage(authInfo);

      var currentmstime = new Date().getTime()
      var newrefreshtime = new Date(authInfo.tokens.access.expires).getTime();

      if (currentmstime >= newrefreshtime) {
        setAuthInfo({});
        setAuthInfoInStorage({});
        if (refreshTimerID)
          clearTimeout(refreshTimerID);
        setRefreshTimerID(null);
        return
      }

      const timedelta = Math.floor((newrefreshtime - currentmstime) * 2/3)

      var timerID = setTimeout(() => {
        refreshAuthentication(authInfo.tokens.refresh.token).then(response => {
          if (response.access && response.refresh) {
            Tracking.logAction("Refreshed authentication")
            setAuthInfo(authInfo => ({
              ...authInfo,
              ...{
                'tokens': {'access': response.access, 'refresh': response.refresh}
              }
            }));
          }
        });
      }, timedelta);

      setRefreshTimerID(
        timerID
      );
      
    }
  }, [authInfo])

  const createNewChat = () => {
    var newChat = newConversationCall(authInfo.user.id, authInfo);
    newChat.then(value => {
      if (allChats.length > 0) {
        const selectedChat = allChats[allChats.length-1]
        if (!selectedChat.chat || selectedChat.chat.length === 0) {
          
          setCurrentChat(() => {
            selectedChat['id'] = value;
            return selectedChat
          });
          return
        }
      }

      setCurrentChat({
        id: value,
        chat: []
      })
    })
  }

  const pressCreateChat = () => {
    Tracking.logAction("Pressed new chat");
    return createNewChat();
  }

  const userSignin = async (email, password) => {
    Tracking.logAction("Attempted sign in");
    var res = await signIn(email, password);
    if (res.user && res.tokens) {
      Tracking.identifyUser(res.user.id);
      Tracking.logAction("Signed in");
    }
    return res;
  }

  const userCreateAccount = async(fullName, email, password) => {
    Tracking.logAction("Attempted create account");
    var res = await createAccount(fullName, email, password);
    if (res.user && res.tokens) {
      Tracking.identifyUser(res.user.id);
      Tracking.logAction("Created account");
    }
    return res;
  }
    
  const getChats = async () => {
    var newConversations = await getConversations(authInfo.user.id, authInfo)
    var toUpdate = newConversations.filter(chat => chat.id !== currentChat.id && chat.messages !== undefined && chat.messages.length > 0);
    setAllChats((prev) => {
      var finalToUpdate = [];

      for (var i=0; i<toUpdate.length; i++)
        finalToUpdate.push(getFrontendConversation(toUpdate[i]));
      

      const newChats = [...finalToUpdate, ...(currentChat.chat && currentChat.chat.messages.length !== 0 ? [currentChat] : [])];
      
      return newChats.sort((a, b) => b.createdAt - a.createdAt);
    });
  }

  const toPopulateChat = (content, type) => {
    const topic = type === 'image' ? 'Image' : content;

    const newConversation = {
      createdAt: Date.now(),
      topic: topic
    }
    return newConversation
  }

  const selectChat = (id, topic) => {
    Tracking.logAction("Switched chats");
    const selectedChat = allChats.find(chat => chat.id === id && chat.topic === topic);
    setCurrentChat(() => selectedChat);
  }

  const addQuestion = (content, type, onUpload, interaction=null) => {
    var toAdd = null;

    if (!currentChat.chat || currentChat.chat.length === 0) {
      if (typeof content === "object")
        toAdd = toPopulateChat(content[0], type[0])
      else
        toAdd = toPopulateChat(content, type);
    }

    const userMessages = []
    if (typeof content === "object")
      for (var i = 0; i<content.length; i++)
        userMessages.push({user: 'user', content: content[i], type: type[i]})
    else
      userMessages.push({user: 'user', content: content, type: type})

    setCurrentChat((currentChat) => {
        return {
          ...currentChat,
          ...(toAdd ? toAdd : {}),
          chat: [...(currentChat.chat ? currentChat.chat : []), ...userMessages]
        }
    });
    addResponse(content, onUpload, interaction);

  }

  const addResponse = async (content, onUpload, interaction=null) => {
    if (typeof content === 'string' && content.indexOf("data:image") === 0 && content.indexOf(";base64,") !== -1) {
      Tracking.logAction("Sent image upload");
      const imageResponse = await sendImage(content, currentChat.id, authInfo);
      if (imageResponse.ok)
        Tracking.logAction("Image uploaded successfully");
      onUpload(true);
      setCurrentChat((prev) => {
        const last_chat_message = prev['chat'][prev['chat'].length-1]
        if (last_chat_message['type'] === 'image') {
          return {
            ...prev,
            chat: [
              ...prev.chat.slice(0, prev['chat'].length-1), 
              {...last_chat_message, 'imageText': imageResponse['text']}]
          }
        }
        return prev
      });
    }
    else if (typeof content === 'string') {    
      var response = null;
      Tracking.logAction("Sent message");
      response = await sendContent(content, currentChat, currentChat.id, authInfo, interaction);
      var responseValid = true;
      response.forEach(item => {
        if (!"user" in item || !"content" in item || !"type" in item)
          responseValid = false;
      });
      
      if (responseValid)
        Tracking.logAction("Received message response");
      else
        throw new Error("malformed response");

      var toAdd = response.map(item => {
        return {"user": item["user"], "content": item["content"], "type": item["type"]}
      })

      onUpload();
      setCurrentChat((prev) => {
        return {
          ...prev,
          chat: [...prev.chat, ...toAdd]
        }
      });
    }
    else if (typeof content === 'object' && content.length && content.length === 2 && typeof content[0] === 'string' && content[0].indexOf("data:image") === 0 && content[0].indexOf(";base64,") !== -1) {
      Tracking.logAction("Sent image upload");
      
      const imageContentResponse = await sendImageAndContent(content[0], content[1], currentChat, currentChat.id, authInfo, interaction);

      var responseValid = true;
      imageContentResponse.forEach(item => {
        if (!"user" in item || !"content" in item || !"type" in item)
          responseValid = false;
      });

      if (responseValid) {
        Tracking.logAction("Image uploaded successfully");
        Tracking.logAction("Received message response");
      }
      else
        throw new Error("malformed response");

      onUpload(true);

      var toAdd = imageContentResponse.slice(1).map(item => {
        return {"user": item["user"], "content": item["content"], "type": item["type"]}
      })

      setCurrentChat((prev) => {
        const second_to_last_chat_message = prev['chat'][prev['chat'].length-2]
        
        return {
            ...prev,
            chat: [
              ...prev.chat.slice(0, prev['chat'].length-2),
              {...second_to_last_chat_message, 'imageText': imageContentResponse[0]['imageText']},
              prev['chat'][prev['chat'].length-1],
              ...toAdd
            ]
          }
        }
      );
    }
  }

  const toggleDrawer = e => {
    if (
      e &&
      e.type === 'keydown' &&
      (e.key === 'Tab' || e.key === 'Shift')
    ) {
      return;
    }
    Tracking.logAction("Toggled chats menu", {"open": !drawerOpen});

    setDrawerOpen((prev) => !prev);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        authInfo && Object.keys(authInfo).length > 0 ?
        <ChatPage
            isMobile={isMobile}
            allChats={allChats}
            selectChat={selectChat}
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerOpen}
            createNewChat={pressCreateChat}
            getChats={getChats}
            currentChat={currentChat}
            addQuestion={addQuestion}
            toggleSettingsView={toggleSettingsView}
            settingsView={settingsView}
            isStudent={isStudent}
            showClassModal={showClassModal}
            toggleShowClassModal={toggleShowClassModal}
          />
          :
          <LoginPage 
            isMobile={isMobile}
            signIn={userSignin}
            createAccount={userCreateAccount}
            forgotPassword={forgotPassword}
            setAuthInfo={setAuthInfo}
          />
      ),
    },
    {
      path: "/verify",
      element: (
        <VerificationPage
          isMobile={isMobile}
          verificationFunction={userVerification}
        />
      )
    }
  ]);

  return (
    <Box
      sx={{
        right: 0,
        margin: 0,
        padding: 0,
        backgroundColor: 'var(--secondary-color-white)'
      }}
    >
      <RouterProvider router={router} />
    
    </Box>
  );
}

export default App;