import { useEffect, useState } from 'react';
import { PrevChatDrawer } from '../PrevChatDrawer/PrevChatDrawer';
import { Header } from '../Header/Header';
import { ChatArea } from '../ChatArea/ChatArea';
import { UserInputField } from '../UserInputField/UserInputField';
import { ClassModal } from '../ClassModal/ClassModal';
import Alert from '@mui/material/Alert';

export const ChatPage = ({ isMobile, allChats, selectChat, createNewChat, currentChat, addQuestion, toggleDrawer, drawerOpen, settingsView, toggleSettingsView, isStudent, showClassModal, toggleShowClassModal }) => {
  const [uploading, setUploading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!uploading && showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000)
    }
  }, [uploading, showAlert]);

  return ([
    showAlert && <Alert
      variant='filled'
      severity='success'
      sx={{
        zIndex: 10,
        position: 'absolute',
        top: '13vh',
        right: 5,
      }}
    >
      Image uploaded successfully! What do you want to know about it?
    </Alert>
      ,
    <PrevChatDrawer 
      allChats={allChats}
      selectChat={selectChat}
      toggleDrawer={toggleDrawer}
      open={drawerOpen}
      isMobile={isMobile}
      toggleSettingsView={toggleSettingsView}
      settingsView={settingsView}
      isStudent={isStudent}
      showClassModal={showClassModal}
      toggleShowClassModal={toggleShowClassModal}
    />
      ,
    <Header
      allChats={allChats}
      selectChat={selectChat}
      createNewChat={createNewChat}
      toggleDrawer={toggleDrawer}
      isMobile={isMobile}
    />
      ,
    <ChatArea
      currentChat={currentChat}
      isMobile={isMobile}
      uploading={uploading}
      addQuestion={addQuestion}
    />
      ,
    <UserInputField
      addQuestion={addQuestion}
      isMobile={isMobile}
      uploading={uploading}
      setUploading={setUploading}
      setShowAlert={setShowAlert}
    />
  ]);
}  