import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChatIcon from '@mui/icons-material/Chat';

export const PrevChatCard = ({ topic, selectChat, id, toggleDrawer }) => {

  const handleClick = () => {
    selectChat(id, topic);
    toggleDrawer();
  }

  const truncateTopic = (topic) => {
    if (topic && topic.length > 35) {
      return topic.slice(0, 35) + '...';
    } else {
      return topic;
    }
  }

  return (
    <ListItem>
      <ListItemButton
        onClick={() => handleClick()}
      >
        <ListItemIcon>
          <ChatIcon />
        </ListItemIcon>
        <ListItemText
          primary={truncateTopic(topic)}
        />
      </ListItemButton>
    </ListItem>
  );
}
